


































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores} from '@/store/poultry/reportType';
import { ReportTypeIds } from '../../constants';

@Component({})
export default class Dropdown extends Vue {
  isActive = false;
  selected!: DropdownOption;
  reportTypeDetailStore = reportTypeStores.detail;

  @Prop({
    type: Array,
    default: [],
    required: true
  })
  private options!: DropdownOption[];

  @Prop()
  private selectedOption!: DropdownOption | string;

  @Prop() disabled!: boolean;
  @Prop() isAnalysis!:boolean;

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.selected = data;
    return data;
  }

  created() {
    this.initializeDefaultOption();
  }

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  }

  changeBorder(){
    if (String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.water) {
      return 'water';
    }
    if (String(this.reportTypeDetailStore.reportTypeId) ===  ReportTypeIds.injectable) {
      return 'injectable';
    }
    if (String(this.reportTypeDetailStore.reportTypeId) ===  ReportTypeIds.hatchery) {
      return 'hatchery';
    }
  }

  changeDropdownColor(){
    if (String(this.reportTypeDetailStore.reportTypeId) ===  ReportTypeIds.water) {
      return 'is-active-water';
    } else if (String(this.reportTypeDetailStore.reportTypeId) ===  ReportTypeIds.injectable) {
      return 'is-active-injectable';
    } else if (String(this.reportTypeDetailStore.reportTypeId) ===  ReportTypeIds.hatchery) {
      return 'is-active-hatchery';
    }
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false;
    }
  }

  initializeDefaultOption() {
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => {
        el.value === this.selectedOption
      });

      if (option) {
        this.selected = option;
      } else {
        this.selected = this.options[0];
      }
    }
  }
}
