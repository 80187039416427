




























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import DropdownPagination from './DropdownPagination.vue'
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { PaginationModel } from '@/models/poultry/pagination.model'
import { DEFAULT_COUNT_PER_PAGE } from '@/constants'

@Component({
  components: {
    DropdownPagination
  }
})
export default class Pagination extends Vue {
  @Prop() pagination!: PaginationModel

  availableResultPerPageOptions = Array.from(Array(4).keys()).map(value => {
    return DEFAULT_COUNT_PER_PAGE * (value + 1);
  });

  resultPerPage = this.availableResultPerPageOptions[0]

  isActive: boolean = false

  mounted() {
    document.addEventListener('click', this.closeDropdown)
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown)
  }

  get range(): string {
    const { offset, limit, } = this.pagination;
    const startResult = ((offset - 1) * limit) + 1;

    return `${startResult} - ${this.currentCount}`;
  }

  get selectedPaginationOption () {
    return {
      text: String(this.pagination.limit),
      value: String(this.pagination.limit)
    }
  }

  get currentCount() {
    const { offset, limit, totalPages, totalRecords } = this.pagination

    // Need to include totalPages and page to prevent undefined value
    if (totalPages && offset && (totalPages - offset) === 0) {
      return totalRecords
    }
    return offset * limit
  }

  get hasMorePages(): boolean {
    if (!this.pagination.totalRecords) {
      return false
    }
    const { limit, totalRecords } = this.pagination

    return limit < totalRecords
  }

  get resultsPerPageDropdownOptions(): DropdownOption[] {
    return this.availableResultPerPageOptions
      .map(value => {
        return String(value)
      })
      .map(value => {
        return {
          text: value,
          value
        }
      })
  }

  get isFirstPage(): boolean {
    return this.pagination.offset === 1
  }

  get isLastPage(): boolean {
    const { offset, totalPages } = this.pagination
    return offset === totalPages
  }

  @Emit('change')
  handlePageCountChanged(params: DropdownOption) {
    return {
      offset: 1,
      limit: Number(params.value)
    }
  }

  @Emit('change')
  handlePreviousPageClicked(): PaginationModel | undefined {
    const { offset, limit } = this.pagination

    if (offset === 1) {
      return;
    }

    return {
      offset: offset - 1,
      limit: limit
    }
  }

  @Emit('change')
  handlePageSelected(offset: number): PaginationModel {
    return {
      offset,
      limit: this.pagination.limit
    }
  }

  @Emit('change')
  handleNextPageClicked(): PaginationModel | undefined {
    const { offset, limit, totalPages } = this.pagination

    if (offset === totalPages) {
      return
    }

    return {
      offset: offset + 1,
      limit: limit
    }
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false
    }
  }
}
